/* eslint-disable max-lines */
/* eslint-disable typescript-enum/no-enum */
/* eslint-disable @typescript-eslint/no-explicit-any */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any }
}

export type Abilities = {
  __typename?: 'Abilities'
  abilitiesJourneys: Scalars['String']['output'][]
  addedAbilities: Scalars['String']['output'][]
  deniedAbilities: Scalars['String']['output'][]
}

export type AbilitiesInput = {
  abilitiesJourneys: Scalars['String']['input'][]
  addedAbilities: Scalars['String']['input'][]
  deniedAbilities: Scalars['String']['input'][]
}

export type Ability = {
  __typename?: 'Ability'
  action: AbilityAction
  inverted?: Maybe<Scalars['Boolean']['output']>
  subject: Scalars['String']['output']
}

export enum AbilityAction {
  Arbitrate = 'ARBITRATE',
  Cancel = 'CANCEL',
  Contact = 'CONTACT',
  Create = 'CREATE',
  Delete = 'DELETE',
  Export = 'EXPORT',
  Finish = 'FINISH',
  Import = 'IMPORT',
  Invite = 'INVITE',
  Join = 'JOIN',
  List = 'LIST',
  Publish = 'PUBLISH',
  Read = 'READ',
  Search = 'SEARCH',
  Send = 'SEND',
  Update = 'UPDATE',
  Validate = 'VALIDATE',
  Write = 'WRITE'
}

export type AbilityWithName = {
  __typename?: 'AbilityWithName'
  ability: Ability
  name: Scalars['String']['output']
}

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  location?: Maybe<LocationDto>
  streetAddress?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type AgreedLegalDocument = {
  __typename?: 'AgreedLegalDocument'
  agreedAt: Scalars['DateTime']['output']
  agreedVersion: Scalars['Float']['output']
  choices: Scalars['Float']['output']
  legalDocumentId: Scalars['String']['output']
}

export type Appointments = {
  __typename?: 'Appointments'
  firstAppointment?: Maybe<CenterAnalysisAppointment>
  followup?: Maybe<FollowupAppointment>
}

export type AttachBuyerToPaymentTunnelResult = {
  __typename?: 'AttachBuyerToPaymentTunnelResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<PolicePaymentTunnel>
}

export type AvailableAbilities = {
  __typename?: 'AvailableAbilities'
  abilities: AbilityWithName[]
  journeys: JourneyWithAbilities[]
  journeysByProfessionType: JourneysByProfessionType[]
}

export enum BioProtocolValues {
  AntiFood25IgG = 'AntiFood25IgG',
  ApolipoproteinA1 = 'ApolipoproteinA1',
  ApolipoproteinB = 'ApolipoproteinB',
  CystatinC = 'CystatinC',
  DirectBilirubin = 'DirectBilirubin',
  HyaluronicAcid = 'HyaluronicAcid',
  LipoproteinA = 'LipoproteinA',
  Neurotransmitters = 'Neurotransmitters',
  TotalBilirubin = 'TotalBilirubin',
  UrinaryIodine = 'UrinaryIodine',
  UrinaryT3 = 'UrinaryT3'
}

export type BookingSlot = {
  __typename?: 'BookingSlot'
  endDate: Scalars['DateTime']['output']
  expiredAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  startDate: Scalars['DateTime']['output']
  status: BookingSlotStatus
  type: BookingSlotType
}

export type BookingSlotSingleResult = {
  __typename?: 'BookingSlotSingleResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<BookingSlot>
}

export enum BookingSlotStatus {
  Closed = 'closed',
  Open = 'open',
  Pending = 'pending'
}

export enum BookingSlotType {
  CheckUp = 'checkUp',
  FollowUp = 'followUp'
}

export type BooleanResult = {
  __typename?: 'BooleanResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<Scalars['Boolean']['output']>
}

export type Bundles = {
  __typename?: 'Bundles'
  quantity: Scalars['Float']['output']
  type: SubscriptionBundleTypeEnum
}

export type BuyerAddress = {
  city: Scalars['String']['input']
  /** ISO 3166-1 alpha-2 code */
  countryAlpha2: Scalars['String']['input']
  streetAddress: Scalars['String']['input']
  zipCode: Scalars['String']['input']
}

export type BuyerInfos = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  invoiceDetails?: InputMaybe<InvoiceDetails>
  language?: InputMaybe<Languages>
  lastName: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
}

export type CenterAnalysisAppointment = CommonAppointment & {
  __typename?: 'CenterAnalysisAppointment'
  address: Address
  attendeeProfessionalIds: Scalars['String']['output'][]
  attendeeProfessionals: ProfessionalAttendee[]
  bookingPhoneNumber: Scalars['String']['output']
  cardholderId?: Maybe<Scalars['String']['output']>
  endDate: Scalars['DateTime']['output']
  fakeProfile?: Maybe<FakeProfileDto>
  id: Scalars['ID']['output']
  isRemoteAttendance: Scalars['Boolean']['output']
  qrCode?: Maybe<Scalars['String']['output']>
  startDate: Scalars['DateTime']['output']
}

export type CenterAnalysisAppointmentDto = {
  __typename?: 'CenterAnalysisAppointmentDTO'
  address: Address
  bookingPhoneNumber: Scalars['String']['output']
  fakeProfile?: Maybe<FakeProfileDto>
  qrCode?: Maybe<Scalars['String']['output']>
  type: MetadataType
}

export type Checkup = {
  __typename?: 'Checkup'
  activeAt?: Maybe<Scalars['DateTime']['output']>
  analysisReceivedAt?: Maybe<Scalars['DateTime']['output']>
  appointments: Appointments
  createdAt: Scalars['DateTime']['output']
  documentIds: Scalars['ID']['output'][]
  fakeProfile?: Maybe<FakeProfileDto>
  finishedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  isFinished: Scalars['Boolean']['output']
  mandatoryInfosValidatedAt?: Maybe<Scalars['DateTime']['output']>
  medicalReportSchemaVersion?: Maybe<Scalars['Float']['output']>
  protocol?: Maybe<Protocol>
  questionnaireCompletedAt?: Maybe<Scalars['DateTime']['output']>
  questionnaireProgress: Scalars['Float']['output']
  samplingKit: SamplingKit
  type: CheckupType
}

export type CheckupMember = {
  __typename?: 'CheckupMember'
  appointments: ExportAppointments
  checkups: Checkup[]
  createdAt: Scalars['DateTime']['output']
  currentCheckup?: Maybe<Checkup>
  id: Scalars['ID']['output']
  lifeJournalUnveiled: Scalars['Boolean']['output']
  personalInfos: PersonalInfosDto
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export enum CheckupType {
  Initial = 'Initial',
  Recurrent = 'Recurrent'
}

export type CommonAppointment = {
  attendeeProfessionalIds: Scalars['String']['output'][]
  attendeeProfessionals: ProfessionalAttendee[]
  bookingPhoneNumber: Scalars['String']['output']
  cardholderId?: Maybe<Scalars['String']['output']>
  endDate: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isRemoteAttendance: Scalars['Boolean']['output']
  qrCode?: Maybe<Scalars['String']['output']>
  startDate: Scalars['DateTime']['output']
}

export enum ConeBeamProtocolValues {
  Dental = 'Dental',
  Orl = 'Orl',
  Sinus = 'Sinus',
  Thorax = 'Thorax'
}

export type Contact = {
  __typename?: 'Contact'
  address?: Maybe<Address>
  phone?: Maybe<Scalars['String']['output']>
}

export type CreatePaymentTransactionResult = {
  __typename?: 'CreatePaymentTransactionResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<PolicePaymentTransaction>
}

export type DailyRecurrenceDto = {
  __typename?: 'DailyRecurrenceDTO'
  /** The duration of the recurrence expressed in ISO8601 (one-of with endDate and occurrences) */
  duration?: Maybe<Scalars['String']['output']>
  /** the end date of the recurrence expressed as a Date (with no time) in ISO8601 (one-of with duration and occurrences) */
  endDate?: Maybe<Scalars['String']['output']>
  /** the frequency of the recurrence */
  frequency: Frequency
  /** the interval between each occurrence of the recurrence (1 meaning each occurrence, 2 meaning one every two occurrences) */
  interval?: Maybe<Scalars['Float']['output']>
  /**
   * the number of occurrences of the recurrence (one-of with duration and endDate)
   * @deprecated Use occurrences instead of occurences
   */
  occurences?: Maybe<Scalars['Float']['output']>
  /** the number of occurrences of the recurrence (one-of with duration and endDate) */
  occurrences?: Maybe<Scalars['Int']['output']>
  /** the start date of the recurrence expressed as a Date (with no time) in ISO8601 */
  startDate: Scalars['String']['output']
  /** time at which events should be triggered on each occurrence. Expressed as a time in ISO8601 (at least one is required) */
  timeTable: Scalars['String']['output'][]
}

export type DeliveryAddressDto = {
  __typename?: 'DeliveryAddressDto'
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  streetAddress: Scalars['String']['output']
  zipCode: Scalars['String']['output']
}

export type DeliveryAddressInput = {
  __typename?: 'DeliveryAddressInput'
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  streetAddress: Scalars['String']['output']
  zipCode: Scalars['String']['output']
}

export type DeliveryAddressInputDto = {
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  streetAddress: Scalars['String']['input']
  zipCode: Scalars['String']['input']
}

export enum DexaProtocolValues {
  WithFemurAndSpine = 'WithFemurAndSpine',
  WithoutFemurOrSpine = 'WithoutFemurOrSpine'
}

export enum EventType {
  OneTime = 'OneTime',
  Recurring = 'Recurring'
}

export type ExportAppointments = {
  __typename?: 'ExportAppointments'
  centerAnalysis?: Maybe<OneTimeEvent>
  checkupId?: Maybe<Scalars['ID']['output']>
  followup?: Maybe<OneTimeEvent>
  followupId?: Maybe<Scalars['ID']['output']>
}

export type FakeProfileDto = {
  __typename?: 'FakeProfileDto'
  birthDate: Scalars['String']['output']
  firstName: Scalars['String']['output']
  gender: Gender
  lastName: Scalars['String']['output']
  sampleId: Scalars['String']['output']
}

export type FakeProfileWithMedicalProfileIdDto = {
  __typename?: 'FakeProfileWithMedicalProfileIdDto'
  birthDate: Scalars['String']['output']
  firstName: Scalars['String']['output']
  gender: Gender
  lastName: Scalars['String']['output']
  medicalProfileId: Scalars['String']['output']
  sampleId: Scalars['String']['output']
}

export type FetchOrganizationsResult = {
  __typename?: 'FetchOrganizationsResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<OrganizationConnection>
}

export type FollowupAppointment = CommonAppointment & {
  __typename?: 'FollowupAppointment'
  address?: Maybe<Address>
  attendeeProfessionalIds: Scalars['String']['output'][]
  attendeeProfessionals: ProfessionalAttendee[]
  bookingPhoneNumber: Scalars['String']['output']
  cardholderId?: Maybe<Scalars['String']['output']>
  endDate: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isRemoteAttendance: Scalars['Boolean']['output']
  qrCode?: Maybe<Scalars['String']['output']>
  remoteAppId?: Maybe<Scalars['String']['output']>
  remoteChannel?: Maybe<Scalars['String']['output']>
  startDate: Scalars['DateTime']['output']
}

export type FollowupMeetingDto = {
  __typename?: 'FollowupMeetingDTO'
  address?: Maybe<Address>
  isRemoteAttendance?: Maybe<Scalars['Boolean']['output']>
  qrCode?: Maybe<Scalars['String']['output']>
  type: MetadataType
  /** @deprecated This link is not used anymore. */
  visioLink?: Maybe<Scalars['String']['output']>
}

export enum Frequency {
  Daily = 'daily',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type GroupedBookingSlotResult = {
  __typename?: 'GroupedBookingSlotResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<GroupedBookingSlots[]>
}

export type GroupedBookingSlots = {
  __typename?: 'GroupedBookingSlots'
  bookingSlots: BookingSlot[]
  date: Scalars['String']['output']
}

export type Invitation = {
  __typename?: 'Invitation'
  id: Scalars['String']['output']
}

export type InvitationDto = {
  __typename?: 'InvitationDTO'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
  organization?: Maybe<Organization>
  sentAt?: Maybe<Scalars['DateTime']['output']>
  status?: Maybe<InvitationStatus>
}

export type InvitationResult = {
  __typename?: 'InvitationResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<Invitation>
}

export enum InvitationStatus {
  Canceled = 'Canceled',
  Claimed = 'Claimed',
  Created = 'Created',
  Sent = 'Sent'
}

export type InvitationsResult = {
  __typename?: 'InvitationsResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<InvitationDto[]>
}

export type InviteMemberInput = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  language?: InputMaybe<Languages>
  lastName: Scalars['String']['input']
}

export type InvoiceDetails = {
  address: BuyerAddress
  organizationName?: InputMaybe<Scalars['String']['input']>
}

export type IsAdmin = {
  __typename?: 'IsAdmin'
  isAdmin: Scalars['Boolean']['output']
}

export type IsAdminResult = {
  __typename?: 'IsAdminResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<OrganizationManagerIsAdmin>
}

export type JourneyWithAbilities = {
  __typename?: 'JourneyWithAbilities'
  abilities: AbilityWithName[]
  name: Scalars['String']['output']
}

export type JourneysByProfessionType = {
  __typename?: 'JourneysByProfessionType'
  journeys: Scalars['String']['output'][]
  professionType: ProfessionType
}

export enum LanguageCodeIso6391 {
  En = 'en',
  Fr = 'fr'
}

export enum Languages {
  EnUs = 'en_us',
  FrFr = 'fr_fr'
}

export type LocationDto = {
  __typename?: 'LocationDTO'
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
}

export enum MetadataType {
  CenterAnalysisAppointment = 'CenterAnalysisAppointment',
  FollowupMeeting = 'FollowupMeeting',
  Preparation = 'Preparation',
  RecommendationLinked = 'RecommendationLinked',
  ToolLinked = 'ToolLinked'
}

export type MonthlyRecurrenceDto = {
  __typename?: 'MonthlyRecurrenceDTO'
  /** The duration of the recurrence expressed in ISO8601 (one-of with endDate and occurrences) */
  duration?: Maybe<Scalars['String']['output']>
  /** the end date of the recurrence expressed as a Date (with no time) in ISO8601 (one-of with duration and occurrences) */
  endDate?: Maybe<Scalars['String']['output']>
  /** the frequency of the recurrence */
  frequency: Frequency
  /** the interval between each occurrence of the recurrence (1 meaning each occurrence, 2 meaning one every two occurrences) */
  interval?: Maybe<Scalars['Float']['output']>
  /**
   * the number of occurrences of the recurrence (one-of with duration and endDate)
   * @deprecated Use occurrences instead of occurences
   */
  occurences?: Maybe<Scalars['Float']['output']>
  /** the number of occurrences of the recurrence (one-of with duration and endDate) */
  occurrences?: Maybe<Scalars['Int']['output']>
  /** the start date of the recurrence expressed as a Date (with no time) in ISO8601 */
  startDate: Scalars['String']['output']
  /** time at which events should be triggered on each occurrence. Expressed as a time in ISO8601 (at least one is required) */
  timeTable: Scalars['String']['output'][]
}

export type Mutation = {
  __typename?: 'Mutation'
  attachInfosToPaymentTunnel: AttachBuyerToPaymentTunnelResult
  cancelMemberInvitation: InvitationResult
  contact: BooleanResult
  createPaymentTransaction: CreatePaymentTransactionResult
  /** Invite a B2B member only. */
  inviteMember: InvitationResult
  registerOrganizationManager: OrganizationManagerResult
  submitWebsiteContactForm: BooleanResult
  submitWebsiteInterestedCompanyForm: BooleanResult
}

export type MutationAttachInfosToPaymentTunnelArgs = {
  buyerInfos: BuyerInfos
  clientSecret: Scalars['ID']['input']
}

export type MutationCancelMemberInvitationArgs = {
  invitationId: Scalars['ID']['input']
  organizationId?: InputMaybe<Scalars['ID']['input']>
  organizationIdProvidedByAdmin?: InputMaybe<Scalars['ID']['input']>
}

export type MutationContactArgs = {
  content: Scalars['String']['input']
}

export type MutationCreatePaymentTransactionArgs = {
  bookingSlotId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationInviteMemberArgs = {
  invitation: InviteMemberInput
  subscriptionId: Scalars['ID']['input']
}

export type MutationRegisterOrganizationManagerArgs = {
  invitationId?: InputMaybe<Scalars['String']['input']>
}

export type MutationSubmitWebsiteContactFormArgs = {
  fields: WebsiteContactFormFields
  formId: Scalars['ID']['input']
  portalId: Scalars['ID']['input']
}

export type MutationSubmitWebsiteInterestedCompanyFormArgs = {
  fields: WebsiteInterestedCompanyFormFields
  formId: Scalars['ID']['input']
  portalId: Scalars['ID']['input']
}

export type OneTimeEvent = {
  __typename?: 'OneTimeEvent'
  approvedAt?: Maybe<Scalars['DateTime']['output']>
  attendeeProfessionalIds: Scalars['String']['output'][]
  canceledAt?: Maybe<Scalars['DateTime']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  endDate: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  markedAsDoneAt?: Maybe<Scalars['DateTime']['output']>
  metadata: SerializedMetadataDto
  ownerId: Scalars['ID']['output']
  parent?: Maybe<Scalars['ID']['output']>
  recurringEventId?: Maybe<Scalars['ID']['output']>
  startDate: Scalars['DateTime']['output']
  title: Scalars['String']['output']
  type: EventType
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type OneTimeEventFind = {
  __typename?: 'OneTimeEventFind'
  attendeeProfessionalIds: Scalars['String']['output'][]
  canceledAt?: Maybe<Scalars['DateTime']['output']>
  endDate: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  markedAsDoneAt?: Maybe<Scalars['DateTime']['output']>
  metadata: SerializedMetadataDto
  /** Owner ID of the event e.g. memberId of the owner of the event */
  ownerId: Scalars['String']['output']
  professionalAttendees: ProfessionalAttendee[]
  recurringEventId?: Maybe<Scalars['String']['output']>
  startDate: Scalars['DateTime']['output']
  title: Scalars['String']['output']
}

export type Organization = {
  __typename?: 'Organization'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type OrganizationDto = {
  __typename?: 'OrganizationDTO'
  address: Address
  companyRegistrationNumber: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  phone?: Maybe<Scalars['String']['output']>
}

export type OrganizationManagerDto = {
  __typename?: 'OrganizationManagerDTO'
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
  organizationId?: Maybe<Scalars['String']['output']>
}

export type OrganizationManagerIsAdmin = {
  __typename?: 'OrganizationManagerIsAdmin'
  isAdmin: Scalars['Boolean']['output']
}

export type OrganizationManagerResult = {
  __typename?: 'OrganizationManagerResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<OrganizationManagerDto>
}

export type OrganizationResult = {
  __typename?: 'OrganizationResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<OrganizationDto>
}

export type PaymentInfo = {
  __typename?: 'PaymentInfo'
  paymentDate: Scalars['DateTime']['output']
  paymentReference: Scalars['String']['output']
}

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction'
  clientSecret: Scalars['ID']['output']
  partnerPaymentCustomerId: Scalars['ID']['output']
  paymentProviderPublicKey: Scalars['String']['output']
}

export type PaymentTunnel = {
  __typename?: 'PaymentTunnel'
  id: Scalars['ID']['output']
}

export type PersonalInfosDto = {
  __typename?: 'PersonalInfosDTO'
  birthDate?: Maybe<Scalars['String']['output']>
  contact?: Maybe<Contact>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Gender>
  lastName?: Maybe<Scalars['String']['output']>
}

export type PolicePaymentTransaction = {
  __typename?: 'PolicePaymentTransaction'
  clientSecret: Scalars['ID']['output']
  paymentProviderPublicKey: Scalars['String']['output']
}

export type PolicePaymentTunnel = {
  __typename?: 'PolicePaymentTunnel'
  id: Scalars['ID']['output']
}

export type PreparationDto = {
  __typename?: 'PreparationDTO'
  link: Scalars['String']['output']
  type: MetadataType
}

export enum ProfessionType {
  Doctor = 'DOCTOR',
  Kine = 'KINE',
  Manipulator = 'MANIPULATOR',
  MedicalAssistant = 'MEDICAL_ASSISTANT',
  Nurse = 'NURSE',
  Ophthalmologist = 'OPHTHALMOLOGIST',
  Orthoptist = 'ORTHOPTIST',
  Radiologist = 'RADIOLOGIST',
  Support = 'SUPPORT'
}

export type Professional = {
  __typename?: 'Professional'
  assignedMedicalProfiles: Scalars['String']['output'][]
  avatarId?: Maybe<Scalars['String']['output']>
  customSignatureId?: Maybe<Scalars['String']['output']>
  details: Scalars['JSONObject']['output']
  disabledAt?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  gender: ProfessionalGender
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
  spokenLanguages: LanguageCodeIso6391[]
}

export type ProfessionalAttendee = {
  __typename?: 'ProfessionalAttendee'
  disabledAt?: Maybe<Scalars['DateTime']['output']>
  firstName: Scalars['String']['output']
  gender?: Maybe<ProfessionalGender>
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  professionType?: Maybe<ProfessionType>
}

export enum ProfessionalGender {
  Female = 'FEMALE',
  Male = 'MALE',
  NonBinary = 'NON_BINARY'
}

export type Protocol = {
  __typename?: 'Protocol'
  bio?: Maybe<BioProtocolValues[]>
  coneBeam?: Maybe<ConeBeamProtocolValues>
  dexa?: Maybe<DexaProtocolValues>
}

export type ProtocolDto = {
  bio?: InputMaybe<BioProtocolValues[]>
  coneBeam?: InputMaybe<ConeBeamProtocolValues>
  dexa?: InputMaybe<DexaProtocolValues>
}

export type Query = {
  __typename?: 'Query'
  fetchBookingSlotById: BookingSlotSingleResult
  fetchInvitations: InvitationsResult
  fetchOpenBookingSlots: GroupedBookingSlotResult
  fetchOrganization: OrganizationResult
  fetchOrganizations: FetchOrganizationsResult
  fetchSubscriptions: SubscriptionsResult
  fetchUser: OrganizationManagerResult
  fetchUserIsAdmin: IsAdminResult
  mandatoryGqlQuery: Scalars['String']['output']
}

export type QueryFetchBookingSlotByIdArgs = {
  bookingSlotId: Scalars['ID']['input']
}

export type QueryFetchInvitationsArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>
  organizationIdProvidedByAdmin?: InputMaybe<Scalars['ID']['input']>
}

export type QueryFetchOpenBookingSlotsArgs = {
  types?: InputMaybe<BookingSlotType[]>
}

export type QueryFetchOrganizationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type QueryFetchSubscriptionsArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>
  organizationIdProvidedByAdmin?: InputMaybe<Scalars['ID']['input']>
}

export type RecommendationLinkedDto = {
  __typename?: 'RecommendationLinkedDTO'
  domain: Scalars['String']['output']
  recommendationId: Scalars['String']['output']
  recommendationType: RecommendationType
  type: MetadataType
}

export enum RecommendationType {
  Exercise = 'exercise',
  Lifestyle = 'lifestyle',
  Nutrition = 'nutrition',
  Sleep = 'sleep',
  StressManagement = 'stressManagement',
  Supplement = 'supplement',
  Therapeutic = 'therapeutic'
}

export type SamplingKit = {
  __typename?: 'SamplingKit'
  deliveryInfos?: Maybe<SamplingKitDeliveryInfosDto>
  receivedAt?: Maybe<Scalars['DateTime']['output']>
  sentAt?: Maybe<Scalars['DateTime']['output']>
}

export type SamplingKitDeliveryInfosDto = {
  __typename?: 'SamplingKitDeliveryInfosDto'
  address: DeliveryAddressDto
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phone: Scalars['String']['output']
}

export type SamplingKitInfosFetchResultDto = {
  __typename?: 'SamplingKitInfosFetchResultDto'
  deliveryInfos?: Maybe<SamplingKitDeliveryInfosDto>
}

export type SerializedEventRecurrenceDto =
  | DailyRecurrenceDto
  | MonthlyRecurrenceDto
  | WeeklyRecurrenceDto

export type SerializedMetadataDto =
  | CenterAnalysisAppointmentDto
  | FollowupMeetingDto
  | PreparationDto
  | RecommendationLinkedDto
  | ToolLinkedDto

export type SerializedOneTimeEventDto = {
  __typename?: 'SerializedOneTimeEventDTO'
  attendeeProfessionalIds: Scalars['String']['output'][]
  canceledAt?: Maybe<Scalars['DateTime']['output']>
  endDate: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  markedAsDoneAt?: Maybe<Scalars['DateTime']['output']>
  metadata: SerializedMetadataDto
  /** Owner ID of the event e.g. memberId of the owner of the event */
  ownerId: Scalars['String']['output']
  recurringEventId?: Maybe<Scalars['String']['output']>
  startDate: Scalars['DateTime']['output']
  title: Scalars['String']['output']
}

export type SerializedRecurringEventDto = {
  __typename?: 'SerializedRecurringEventDTO'
  attendeeProfessionalIds: Scalars['String']['output'][]
  durationInMinutes: Scalars['Float']['output']
  effectiveEndDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  metadata: SerializedMetadataDto
  /** Owner ID of the event e.g. memberId of the owner of the event */
  ownerId: Scalars['String']['output']
  recurrence: SerializedEventRecurrenceDto
  title: Scalars['String']['output']
}

export type Subscriber = {
  __typename?: 'Subscriber'
  subscriberId: Scalars['ID']['output']
  type: SubscriberTypeEnum
}

export enum SubscriberTypeEnum {
  Buyer = 'Buyer',
  Member = 'Member',
  Organization = 'Organization'
}

export type Subscription = {
  __typename?: 'Subscription'
  balance?: Maybe<PaymentInfo>
  bundles?: Maybe<Bundles[]>
  deposit?: Maybe<PaymentInfo>
  id: Scalars['ID']['output']
  startDate?: Maybe<Scalars['DateTime']['output']>
  status: SubscriptionStatusEnum
  subscriber: Subscriber
}

export enum SubscriptionBundleTypeEnum {
  FirstYear = 'FirstYear',
  Renewal = 'Renewal'
}

export enum SubscriptionStatusEnum {
  Paid = 'Paid',
  WaitingForBalance = 'WaitingForBalance',
  WaitingForDeposit = 'WaitingForDeposit',
  WaitingForPayment = 'WaitingForPayment'
}

export type SubscriptionsResult = {
  __typename?: 'SubscriptionsResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<Subscription[]>
}

export type ToolLinkedDto = {
  __typename?: 'ToolLinkedDto'
  fastingType?: Maybe<Scalars['String']['output']>
  skippedMeal?: Maybe<Scalars['String']['output']>
  toolType: Scalars['String']['output']
  type: MetadataType
  weekDay?: Maybe<Scalars['String']['output']>
}

export type User = {
  __typename?: 'User'
  abilitiesJourneys?: Maybe<Scalars['String']['output'][]>
  agreedLegalDocuments?: Maybe<AgreedLegalDocument[]>
  authId: Scalars['String']['output']
  id: Scalars['String']['output']
  isAdmin: Scalars['Boolean']['output']
  memberId?: Maybe<Scalars['String']['output']>
  moreAbilities?: Maybe<Ability[]>
  professionalId?: Maybe<Scalars['String']['output']>
}

export type WebsiteContactFormFields = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  message: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
}

export type WebsiteInterestedCompanyFormFields = {
  company: Scalars['String']['input']
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  jobTitle: Scalars['String']['input']
  lastName: Scalars['String']['input']
  message: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
  potentialSubscriptionsInterest: Scalars['String']['input']
}

export enum Weekday {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type WeeklyRecurrenceDto = {
  __typename?: 'WeeklyRecurrenceDTO'
  /** The duration of the recurrence expressed in ISO8601 (one-of with endDate and occurrences) */
  duration?: Maybe<Scalars['String']['output']>
  /** the end date of the recurrence expressed as a Date (with no time) in ISO8601 (one-of with duration and occurrences) */
  endDate?: Maybe<Scalars['String']['output']>
  /** the frequency of the recurrence */
  frequency: Frequency
  /** the interval between each occurrence of the recurrence (1 meaning each occurrence, 2 meaning one every two occurrences) */
  interval?: Maybe<Scalars['Float']['output']>
  /**
   * the number of occurrences of the recurrence (one-of with duration and endDate)
   * @deprecated Use occurrences instead of occurences
   */
  occurences?: Maybe<Scalars['Float']['output']>
  /** the number of occurrences of the recurrence (one-of with duration and endDate) */
  occurrences?: Maybe<Scalars['Int']['output']>
  /** the start date of the recurrence expressed as a Date (with no time) in ISO8601 */
  startDate: Scalars['String']['output']
  /** time at which events should be triggered on each occurrence. Expressed as a time in ISO8601 (at least one is required) */
  timeTable: Scalars['String']['output'][]
  weekdays?: Maybe<Weekday[]>
}

/** Pagination connection for 'organization'. For more information, visit https://relay.dev/graphql/connections.htm */
export type OrganizationConnection = {
  __typename?: 'organizationConnection'
  /** Content of the page */
  edges: OrganizationEdge[]
  /** Metadata about the page */
  pageInfo: OrganizationPageInfo
}

/** Contains a single pagination node (an entity, organization) along with its cursor */
export type OrganizationEdge = {
  __typename?: 'organizationEdge'
  /** Cursor associated to the entity */
  cursor: Scalars['ID']['output']
  /** Entity (organization) itself */
  node: OrganizationDto
}

/** Contains information concerning presence of next or previous page as well as cursors to access them. */
export type OrganizationPageInfo = {
  __typename?: 'organizationPageInfo'
  /** Last cursor of the current page, same as edges.at(-1).cursor */
  endCursor?: Maybe<Scalars['ID']['output']>
  /** Indicates presence of next page, or possibility to paginate forward */
  hasNextPage: Scalars['Boolean']['output']
  /** Indicates presence of previous page, or possibility to paginate backward */
  hasPreviousPage: Scalars['Boolean']['output']
  /** First cursor of the current page, same as edges.at(0).cursor */
  startCursor?: Maybe<Scalars['ID']['output']>
}
