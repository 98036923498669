import type { InitOptions } from 'i18next'
import type { FsBackendOptions } from 'i18next-fs-backend'
import type { LocizeBackendOptions } from 'i18next-locize-backend'
import { Languages } from '~/@types/graphql-police'
import { API_MODE, LOCIZE_PROJECT_ID } from '~/constants/env'
import { matchIsBrowser } from '~/helpers/navigator'
import { matchIsNodeProduction } from '~/utils/env'

const I18N_FILE_SYSTEM_LOCALE_PATH = './public/locales/{{lng}}/{{ns}}.json'

export const I18N_FILE_SYSTEM_BACKEND_CONFIG = {
  loadPath: I18N_FILE_SYSTEM_LOCALE_PATH,
  addPath: I18N_FILE_SYSTEM_LOCALE_PATH,
  // 2 minutes for dev env, 15 minutes for other envs
  expirationTime: (matchIsNodeProduction() ? 15 : 2) * 60 * 1000
} satisfies FsBackendOptions

const getI18nVersion = () => {
  if (API_MODE === 'production' || API_MODE === 'hotfix') {
    return 'prod'
  }

  return 'latest'
}

export const I18N_LOCIZE_BACKEND_CONFIG = {
  projectId: LOCIZE_PROJECT_ID,
  reloadInterval: false,
  version: getI18nVersion()
} satisfies LocizeBackendOptions

export const SUPPORTED_LOCALES = ['en', 'fr'] as const
export type Locale = (typeof SUPPORTED_LOCALES)[number]

export const LANGUAGES_BY_LOCALE: ValuesByLocale<Languages> = {
  en: Languages.EnUs,
  fr: Languages.FrFr
}

export const defaultNS = 'common'

export type ValuesByLocale<T> = {
  [key in Locale]: T
}

export const PREFIXED_PATHS_BY_LOCALE = {
  fr: '/fr',
  en: '/en'
} satisfies {
  [key in Locale]: `/${key}`
}

export const i18nextConfig = {
  supportedLngs: SUPPORTED_LOCALES,
  fallbackLng: 'fr' as const,
  defaultNS,
  debug: !matchIsNodeProduction() && matchIsBrowser(),
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  backend: I18N_LOCIZE_BACKEND_CONFIG
} satisfies InitOptions<LocizeBackendOptions>
